








































































































































































































































































































































import { Component } from "vue-property-decorator";
import Swatch from "@/components/designer/fabric/Swatch.vue";
import RadioInput from "@/components/designer/reusable/RadioInput.vue";
import FabricSelector from "@/components/designer/fabric/picker/FabricSelector.vue";
import CanopyLayouts from "./CanopyLayouts";

@Component({
  components: {
    Swatch,
    RadioInput,
    FabricSelector,
  },
})
export default class D2D3 extends CanopyLayouts {
  mounted() {
    this.readStoreData();
  }

  protected readStoreData(): void {
    this.selectedRib = this.rib;
  }
}
